import React, { useEffect } from "react";
import NavigationComponent from "../../components/navigation";
import styled, { keyframes, css } from "styled-components";
import "./Styles.scss";
import FooterComponent from "../../components/footer";
import { breadCrumbs$, handleCart$, appID } from "../../shared/app-service";
import PillComponent from "../../components/pill";
import CartComponent from "../../components/cart";
import { handleRoute } from "@cmj526/made-for-us";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as mobile from "is-mobile";
import ModalCard from "./components/ModalCard";

const ProductFadeIn = keyframes`
0% {
  opacity: .5;
  transform: translateX(10px) translateY(0);
}

100% {
  opacity: 1;
  transform: translateX(0) translateY(-10px);
}
`;

const ProductFadeOut = keyframes`
0% {
  opacity: .5;
  transform: translateX(0) translateY(-10px);
}

100% {
  opacity: 1;
  transform: translateX(10px) translateY(0);
}
`;

const ProductZoomIn = keyframes`
0% {
  opacity: .5;
  transform: scale(1);
}

100% {
  opacity: 1;
  transform: scale(1.1);
}
`;

const ProductZoomInMore = keyframes`
0% {
  opacity: .5;
  // filter: blur(10px);
  transform: scale(1.1);
}

100% {
  opacity: 1;
  filter: blur(0);
  transform: scale(1.8);
}
`;
const Shirts = styled.div`
  cursor: pointer;
  position: relative;
  // top: 20%;
  padding: 0.2em 0.3em;
  border: 1px solid rgba(136, 136, 138, 0.5);
  border-radius: 5px;
  opacity: 1;
  margin: 0 2em;
  display: flex;
  flex-direction: row;
  width: 24em;

  &:hover {
    border: 1px solid rgba(136, 136, 138, 1);
    background-color: rgba(136, 136, 138, 0.5);
    &_title_top {
      color: black;
    }
  }

  ${(props) =>
    props.itemSelected &&
    css`
      border: 2px solid #ffc917;
      &_title_top {
        color: black;
      }
    `}
`;

const ShirtsImg = styled.img`
  width: 4em;
  height: 4em;
`;

const ShirtsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const ShirtsTitle = styled.span`
  font-family: "Varela Round";
  font-size: 1.2em !important;
  height: 0.3em;
  padding: 0.6em 0;
  display: block;
  color: white;
`;

const ShirtsSubtitle = styled.span`
  font-size: 0.7em !important;
  font-family: "Varela Round";
  font-size: 1em;
  color: #88888a;
`;

const Tattoo = styled.div`
  cursor: pointer;
  position: relative;
  // top: 22%;
  padding: 0.2em 0.3em;
  border: 1px solid rgba(136, 136, 138, 0.5);
  border-radius: 5px;
  opacity: 1;
  margin: 0 2em;
  display: flex;
  flex-direction: row;
  width: 24em;

  &:hover {
    border: 1px solid rgba(136, 136, 138, 1);
    background-color: rgba(136, 136, 138, 0.5);
    &_title_top {
      color: black;
    }
  }

  ${(props) =>
    props.itemSelected &&
    css`
      border: 2px solid #ffc917;
      &_title_top {
        color: black;
      }
    `}
`;

const TattooImg = styled.img`
  width: 4em;
  height: 4em;
`;

const TattooContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const TattooTitle = styled.span`
  font-family: "Varela Round";
  font-size: 1.2em !important;
  height: 0.3em;
  padding: 0.6em 0;
  display: block;
  color: white;
`;

const TattooSubtitle = styled.span`
  font-size: 0.7em !important;
  font-family: "Varela Round";
  font-size: 1em;
  color: #88888a;
`;

const Jewelry = styled.div`
  cursor: pointer;
  position: relative;
  // top: 24%;
  padding: 0.2em 0.3em;
  border: 1px solid rgba(136, 136, 138, 0.5);
  border-radius: 5px;
  opacity: 1;
  margin: 0 2em;
  display: flex;
  flex-direction: row;
  width: 24em;

  &:hover {
    border: 1px solid rgba(136, 136, 138, 1);
    background-color: rgba(136, 136, 138, 0.5);
    &_title_top {
      color: black;
    }
  }

  ${(props) =>
    props.itemSelected &&
    css`
      border: 2px solid #ffc917;
      &_title_top {
        color: black;
      }
    `}
`;

const JewelryImg = styled.img`
  width: 4em;
  height: 4em;
`;

const JewelryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const JewelryTitle = styled.span`
  font-family: "Varela Round";
  font-size: 1.2em !important;
  height: 0.3em;
  padding: 0.6em 0;
  display: block;
  color: white;
`;

const JewelrySubtitle = styled.span`
  font-size: 0.7em !important;
  font-family: "Varela Round";
  font-size: 1em;
  color: #88888a;
`;

const ShopItemPrice = styled.div`
  font-size: 0.8em;
  color: #88888a;
`;

const ShopItem = styled.div`
  ${(props) =>
    props.itemSelected &&
    css`
      animation: ${ProductFadeIn} 0.5s ease-in-out forwards;
    `}
`;

const ShopStore = styled.div`
  ${(props) =>
    props.itemHovered &&
    css`
      ${ShopItemPrice} {
        transform: translateY(80%) !important;
      }
    `}
`;

const BuyNow = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  z-index: -1;
  color: #050505;
  border-radius: 4px;
  transform: translateY(0%);
  background-color: #ffc917;
  border: solid 1px #01e0572d;
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
`;

const BuyButton = styled.button`
  position: relative;
  border-width: 0;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  background-color: black;
  z-index: 1;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    ${BuyNow} {
      transform: translateY(50%) !important;
    }
  }

  ${(props) =>
    props.itemHovered &&
    css`
      ${BuyNow} {
        transform: translateY(50%) !important;
      }
    `}
`;

const BuyIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  min-height: 40px;
  padding: 0 0.2em;
  border-radius: 4px;
  z-index: 1;
  background-color: rgba(5, 5, 5, 1);
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
`;

const ApparelHovered = styled.img`
  opacity: 0;
  animation: ${ProductFadeIn} 2s ease-in-out forwards;
`;

const ApparelNotHovered = styled.img`
  opacity: 0;
  animation: ${ProductFadeOut} 2s ease-in-out forwards;
`;

const AfterCareHovered = styled.img`
  opacity: 0;
  animation: ${ProductZoomIn} 2s ease-in-out forwards;
`;

const AfterCareNotHovered = styled.img`
  animation: ${ProductZoomInMore} 2s ease-in-out forwards;
`;

const ShopComponent = ({
  pageData: { store },
  itemSelected,
  setItemSelected,
  setItemHovered,
  itemHovered,
  apparelType,
  setApparelType,
  aftercareType,
  setAftercareType,
  smokeType,
  setSmokeType,
  jewelryType,
  setJewelryType,
  selectedSize,
  setSelectedSize,
  cartShowing,
  addApparelToCart,
  addToCart,
  videoEl,
  isVideoPlaying,
}) => {
  const navigate = useNavigate();

  const handleSelect = (size) => {
    setSelectedSize(size);
  };

  return (
    <>
      <div className="shop">
        <NavigationComponent />
        <div className="overlay-shop-menu">
          <div className="overlay-shop-menu_title">
            {mobile({
              featureDetect: true,
              tablet: true,
            }) && (
              <>
                <ul id="d-mobile">
                  <li>S</li>
                  <li>T</li>
                  <li>O</li>
                  <li>R</li>
                  <li>E</li>
                </ul>
              </>
            )}

            {!mobile({
              featureDetect: true,
              tablet: true,
            }) && (
              <>
                <ul id="d">
                  <li>D</li>
                  <li>Y</li>
                  <li>N</li>
                  <li>A</li>
                  <li>S</li>
                  <li>T</li>
                  <li>Y</li>
                </ul>

                <ul id="s">
                  <li>S</li>
                  <li>T</li>
                  <li>O</li>
                  <li>R</li>
                  <li>E</li>
                </ul>
              </>
            )}
          </div>
          <div className="overlay-shop-menu_subtitle">
            Check out the amazing and enchanting products offered at our
            incredible tattoo shop! You won't believe the magic we can create
            for you!
          </div>
          {store?.openForBusiness?.apparel?.isOpen && (
            <Shirts
              onClick={() => {
                breadCrumbs$.next([
                  {
                    name: "Home",
                    link: "/main",
                    hasCrumb: true,
                  },
                  {
                    name: "Apparel",
                    link: "/store",
                    selected: "shirts",
                    hasCrumb: false,
                  },
                ]);
                setItemSelected("shirts");
                setAftercareType("");
                setApparelType("");
                setJewelryType("");
              }}
              itemSelected={itemSelected === "shirts"}
            >
              <ShirtsImg src={store?.openForBusiness?.apparel?.img} alt="" />
              <ShirtsContent>
                <ShirtsTitle>
                  {store?.openForBusiness?.apparel?.title}
                </ShirtsTitle>
                <ShirtsSubtitle>
                  {store?.openForBusiness?.apparel?.subTitle}
                </ShirtsSubtitle>
              </ShirtsContent>
            </Shirts>
          )}

          {store?.openForBusiness?.jewelry?.isOpen && (
            <Jewelry
              onClick={() => {
                breadCrumbs$.next([
                  {
                    name: "Home",
                    link: "/main",
                    hasCrumb: true,
                  },
                  {
                    name: "Jewelry",
                    link: "/store",
                    selected: "jewelry",
                    hasCrumb: false,
                  },
                ]);
                setItemSelected("jewelry");
                setAftercareType("");
                setApparelType("");
                setJewelryType("");
                setSmokeType("");
              }}
              itemSelected={itemSelected === "jewelry"}
            >
              <JewelryImg src={store?.openForBusiness?.jewelry?.img} alt="" />
              <JewelryContent>
                <JewelryTitle className="overlay-shop-menu_jewelry_title_top">
                  {store?.openForBusiness?.jewelry?.title}
                </JewelryTitle>
                <JewelrySubtitle>
                  {store?.openForBusiness?.jewelry?.subTitle}
                </JewelrySubtitle>
              </JewelryContent>
            </Jewelry>
          )}

          {store?.openForBusiness?.smokeShop?.isOpen && (
            <Jewelry
              onClick={() => {
                breadCrumbs$.next([
                  {
                    name: "Home",
                    link: "/main",
                    hasCrumb: true,
                  },
                  {
                    name: "Smoke Shop",
                    selected: "smoke",
                    link: "/store",
                    hasCrumb: false,
                  },
                ]);
                setItemSelected("smoke");
                setAftercareType("");
                setApparelType("");
                setJewelryType("");
                setSmokeType("");
              }}
              itemSelected={itemSelected === "smoke"}
            >
              <JewelryImg src={store?.openForBusiness?.smokeShop?.img} alt="" />
              <JewelryContent>
                <JewelryTitle className="overlay-shop-menu_jewelry_title_top">
                  {store?.openForBusiness?.smokeShop?.title}
                </JewelryTitle>
                <JewelrySubtitle>
                  {store?.openForBusiness?.smokeShop?.subTitle}
                </JewelrySubtitle>
              </JewelryContent>
            </Jewelry>
          )}

          {store?.openForBusiness?.aftercare?.isOpen && (
            <Tattoo
              onClick={() => {
                breadCrumbs$.next([
                  {
                    name: "Home",
                    link: "/main",
                    hasCrumb: true,
                  },
                  {
                    name: "Aftercare",
                    selected: "aftercare",
                    link: "/store",
                    hasCrumb: false,
                  },
                ]);
                setItemSelected("aftercare");
                setAftercareType("");
                setApparelType("");
                setJewelryType("");
                setSmokeType("");
              }}
              itemSelected={itemSelected === "aftercare"}
            >
              <TattooImg src={store?.openForBusiness?.aftercare?.img} alt="" />
              <TattooContent>
                <TattooTitle>
                  {store?.openForBusiness?.aftercare?.title}
                </TattooTitle>
                <TattooSubtitle>
                  {store?.openForBusiness?.aftercare?.subTitle}
                </TattooSubtitle>
              </TattooContent>
            </Tattoo>
          )}

          {isVideoPlaying && (
            <video
              className="shop_video"
              playsInline
              loop
              ref={videoEl}
              autoPlay={true}
              alt="All the devices"
              src="https://bdt-site.s3.us-east-2.amazonaws.com/free-shipping-2.mp4"
            ></video>
          )}

          {!isVideoPlaying && (
            <img
              alt="All the devices"
              className="shop_img"
              src="https://bdt-site.s3.us-east-2.amazonaws.com/free-shipping-2.png"
            />
          )}
        </div>
        {/*---------- APPAREL ---------------*/}
        {itemSelected === "shirts" && (
          <ShopStore className="shop_modals">
            {apparelType === "" && (
              <>
                <div
                  onClick={() => {
                    breadCrumbs$.next([
                      {
                        name: "Home",
                        link: "/main",
                        hasCrumb: true,
                      },
                      {
                        name: "Apparel",
                        link: "/store",
                        hasCrumb: true,
                      },
                      {
                        name: "Mens",
                        link: "",
                        hasCrumb: false,
                      },
                    ]);
                    setApparelType("mens");
                  }}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      <img src={store?.mensModal?.img} alt="" />
                    </div>
                    <div className="shop_modals_item_info">
                      <div className="shop_modals_item_info_name">
                        <div className="shop_modals_item_info_name_top">
                          {store?.mensModal?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => {
                    breadCrumbs$.next([
                      {
                        name: "Home",
                        link: "/main",
                        hasCrumb: true,
                      },
                      {
                        name: "Apparel",
                        link: "/store",
                        hasCrumb: true,
                      },
                      {
                        name: "Women",
                        link: "",
                        hasCrumb: false,
                      },
                    ]);
                    setApparelType("womens");
                  }}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      <img src={store?.womensModal?.img} alt="" />
                    </div>
                    <div className="shop_modals_item_info">
                      <div className="shop_modals_item_info_name">
                        <div className="shop_modals_item_info_name_top">
                          {store?.womensModal?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {store &&
              apparelType === "mens" &&
              store?.shirts?.mens?.map((shirt, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <ApparelHovered src={shirt?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <ApparelNotHovered src={shirt?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_modals_item_info">
                    <div className="shop__modals_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {shirt?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {shirt?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container">
                        <div class="tabs">
                          {shirt?.available.length >= 1 && (
                            <PillComponent
                              handleSelect={handleSelect}
                              id={idx}
                              available={shirt?.available}
                              sizes={shirt?.available}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addApparelToCart({ ...shirt, ...selectedSize });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {shirt?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}

            {store &&
              apparelType === "womens" &&
              store?.shirts?.womens?.map((shirt, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <ApparelNotHovered src={shirt?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <ApparelHovered src={shirt?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_store_item_info">
                    <div className="shop_store_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {shirt?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {shirt?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container">
                        <div class="tabs">
                          {shirt?.available.length >= 1 &&
                            shirt?.id !== "WP1" && (
                              <PillComponent
                                handleSelect={handleSelect}
                                id={idx}
                                available={shirt?.available}
                                sizes={shirt?.available}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addApparelToCart({ ...shirt, ...selectedSize });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {shirt?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}
          </ShopStore>
        )}

        {/*---------- JEWELRY ---------------*/}
        {itemSelected === "jewelry" && (
          <ShopStore className="shop_modals">
            {jewelryType === "" && (
              <>
                {/*---------- EARINGS ---------------*/}
                {store?.earingsModal?.shouldShow && (
                  <ModalCard
                    breadCrumbs$={breadCrumbs$}
                    setJewelryType={setJewelryType}
                    store={store}
                    itemType="earings"
                    itemName={store?.earingsModal?.name}
                    img={store?.earingsModal?.img}
                  />
                )}
                {/*---------- FULL SET ---------------*/}
                {store?.fullSetModal?.shouldShow && (
                  <ModalCard
                    breadCrumbs$={breadCrumbs$}
                    setJewelryType={setJewelryType}
                    store={store}
                    itemType="fullset"
                    itemName={store?.fullSetModal?.name}
                    img={store?.fullSetModal?.img}
                  />
                )}

                {/*---------- BODY SET ---------------*/}
                {store?.bodyModal?.shouldShow && (
                  <ModalCard
                    breadCrumbs$={breadCrumbs$}
                    setJewelryType={setJewelryType}
                    store={store}
                    itemType="body"
                    itemName={store?.bodyModal?.name}
                    img={store?.bodyModal?.img}
                  />
                )}

                {/*---------- LONG EAR SET ---------------*/}
                {store?.longEarModal?.shouldShow && (
                  <ModalCard
                    breadCrumbs$={breadCrumbs$}
                    setJewelryType={setJewelryType}
                    store={store}
                    itemType="earlobe"
                    itemName={store?.longEarModal?.name}
                    img={store?.longEarModal?.img}
                  />
                )}

                {/*---------- CRYSTAL SET ---------------*/}
                {store?.crystalsModal?.shouldShow && (
                  <ModalCard
                    breadCrumbs$={breadCrumbs$}
                    setJewelryType={setJewelryType}
                    store={store}
                    itemType="crystals"
                    itemName={store?.crystalsModal?.name}
                    img={store?.crystalsModal?.img}
                  />
                )}
              </>
            )}

            {store &&
              jewelryType === "earings" &&
              store?.jewelry?.earrings?.map((jewelry, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <AfterCareHovered src={jewelry?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <AfterCareHovered src={jewelry?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_modals_item_info">
                    <div className="shop__modals_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {jewelry?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {jewelry?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container">
                        {/* <div class="tabs">
                          {sizes.map((size, idx) => (
                            <PillComponent id={idx} name={size} />
                          ))}
                        </div> */}
                      </div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addToCart({ ...jewelry });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {jewelry?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}

            {store &&
              jewelryType === "fullset" &&
              store?.jewelry?.fullSets?.map((jewelry, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <AfterCareHovered src={jewelry?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <AfterCareNotHovered src={jewelry?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_store_item_info">
                    <div className="shop_store_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {jewelry?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {jewelry?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container">
                        <div class="tabs"></div>
                      </div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addToCart({ ...jewelry });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {jewelry?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}

            {store &&
              jewelryType === "crystals" &&
              store?.jewelry?.crystals?.map((jewelry, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <AfterCareHovered src={jewelry?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <AfterCareNotHovered src={jewelry?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_store_item_info">
                    <div className="shop_store_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {jewelry?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {jewelry?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container">
                        <div class="tabs"></div>
                      </div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addToCart({ ...jewelry });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {jewelry?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}

            {store &&
              jewelryType === "body" &&
              store?.jewelry?.body?.map((jewelry, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <AfterCareHovered src={jewelry?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <AfterCareNotHovered src={jewelry?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_store_item_info">
                    <div className="shop_store_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {jewelry?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {jewelry?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container">
                        <div class="tabs"></div>
                      </div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addToCart({ ...jewelry });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {jewelry?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}

            {store &&
              jewelryType === "earlobe" &&
              store?.jewelry?.earlobe?.map((jewelry, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <AfterCareHovered src={jewelry?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <AfterCareNotHovered src={jewelry?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_store_item_info">
                    <div className="shop_store_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {jewelry?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {jewelry?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container">
                        <div class="tabs"></div>
                      </div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addToCart({ ...jewelry });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {jewelry?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}
          </ShopStore>
        )}
        {/*---------- SMOKE SHOP ---------------*/}
        {itemSelected === "smoke" && (
          <ShopStore className="shop_modals">
            {smokeType === "" && (
              <>
                {/*---------- ROACH ---------------*/}
                {/* <div
                  onClick={() => {
                    breadCrumbs$.next([
                      {
                        name: "Home",
                        link: "/main",
                        hasCrumb: true,
                      },
                      {
                        name: "Smoke Shop",
                        link: "/store",
                        hasCrumb: true,
                      },
                      {
                        name: "Roach Clamps",
                        link: "",
                        hasCrumb: false,
                      },
                    ]);
                    setSmokeType("roach");
                  }}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      <img src={store?.roachModal?.img} alt="" />
                    </div>
                    <div className="shop_modals_item_info">
                      <div className="shop_modals_item_info_name">
                        <div className="shop_modals_item_info_name_top">
                          {store?.roachModal?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/*---------- TRAYS ---------------*/}
                <div
                  onClick={() => {
                    breadCrumbs$.next([
                      {
                        name: "Home",
                        link: "/main",
                        hasCrumb: true,
                      },
                      {
                        name: "Smoke",
                        link: "/store",
                        hasCrumb: true,
                      },
                      {
                        name: "Trays",
                        link: "",
                        hasCrumb: false,
                      },
                    ]);
                    setSmokeType("trays");
                  }}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      <img src={store?.traysModal?.img} alt="" />
                    </div>
                    <div className="shop_modals_item_info">
                      <div className="shop_modals_item_info_name">
                        <div className="shop_modals_item_info_name_top">
                          {store?.traysModal?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*---------- LONG PIPES ---------------*/}
                <div
                  onClick={() => {
                    breadCrumbs$.next([
                      {
                        name: "Home",
                        link: "/main",
                        hasCrumb: true,
                      },
                      {
                        name: "Smoke",
                        link: "/store",
                        hasCrumb: true,
                      },
                      {
                        name: "Pipes",
                        link: "",
                        hasCrumb: false,
                      },
                    ]);
                    setSmokeType("longPipes");
                  }}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      <img src={store?.longPipesModal?.img} alt="" />
                    </div>
                    <div className="shop_modals_item_info">
                      <div className="shop_modals_item_info_name">
                        <div className="shop_modals_item_info_name_top">
                          {store?.longPipesModal?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*---------- SHORT PIPES ---------------*/}
                <div
                  onClick={() => {
                    breadCrumbs$.next([
                      {
                        name: "Home",
                        link: "/main",
                        hasCrumb: true,
                      },
                      {
                        name: "Smoke",
                        link: "/store",
                        hasCrumb: true,
                      },
                      {
                        name: "Pipes",
                        link: "",
                        hasCrumb: false,
                      },
                    ]);
                    setSmokeType("smallPipes");
                  }}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      <img src={store?.pipesModal?.img} alt="" />
                    </div>
                    <div className="shop_modals_item_info">
                      <div className="shop_modals_item_info_name">
                        <div className="shop_modals_item_info_name_top">
                          {store?.pipesModal?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*---------- GRINDERS ---------------*/}
                <div
                  onClick={() => {
                    breadCrumbs$.next([
                      {
                        name: "Home",
                        link: "/main",
                        hasCrumb: true,
                      },
                      {
                        name: "Smoke",
                        link: "/store",
                        hasCrumb: true,
                      },
                      {
                        name: "Grinders",
                        link: "",
                        hasCrumb: false,
                      },
                    ]);
                    setSmokeType("grinders");
                  }}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      <img src={store?.grindersModal?.img} alt="" />
                    </div>
                    <div className="shop_modals_item_info">
                      <div className="shop_modals_item_info_name">
                        <div className="shop_modals_item_info_name_top">
                          {store?.grindersModal?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*---------- PAPERS ---------------*/}
                <div
                  onClick={() => {
                    breadCrumbs$.next([
                      {
                        name: "Home",
                        link: "/main",
                        hasCrumb: true,
                      },
                      {
                        name: "Smoke",
                        link: "/store",
                        hasCrumb: true,
                      },
                      {
                        name: "Papers",
                        link: "",
                        hasCrumb: false,
                      },
                    ]);
                    setSmokeType("papers");
                  }}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      <img src={store?.papersModal?.img} alt="" />
                    </div>
                    <div className="shop_modals_item_info">
                      <div className="shop_modals_item_info_name">
                        <div className="shop_modals_item_info_name_top">
                          {store?.papersModal?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {store &&
              smokeType === "trays" &&
              store?.smoke?.trays?.map((tray, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <AfterCareHovered src={tray?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <AfterCareHovered src={tray?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_modals_item_info">
                    <div className="shop__modals_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {tray?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {tray?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container"></div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addToCart({ ...tray });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {tray?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}

            {store &&
              smokeType === "longPipes" &&
              store?.smoke?.longPipes?.map((pipe, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <AfterCareHovered src={pipe?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <AfterCareHovered src={pipe?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_store_item_info">
                    <div className="shop_store_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {pipe?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {pipe?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container"></div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addToCart({ ...pipe });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {pipe?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}

            {store &&
              smokeType === "smallPipes" &&
              store?.smoke?.smallPipes?.map((pipe, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <AfterCareHovered src={pipe?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <AfterCareHovered src={pipe?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_store_item_info">
                    <div className="shop_store_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {pipe?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {pipe?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container"></div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addToCart({ ...pipe });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {pipe?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}

            {store &&
              smokeType === "grinders" &&
              store?.smoke?.grinders?.map((pipe, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <AfterCareHovered src={pipe?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <AfterCareHovered src={pipe?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_store_item_info">
                    <div className="shop_store_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {pipe?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {pipe?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container"></div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addToCart({ ...pipe });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {pipe?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}

            {store &&
              smokeType === "papers" &&
              store?.smoke?.papers?.map((pipe, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <AfterCareHovered src={pipe?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <AfterCareHovered src={pipe?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_store_item_info">
                    <div className="shop_store_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {pipe?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {pipe?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container"></div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addToCart({ ...pipe });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {pipe?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}
          </ShopStore>
        )}

        {/*---------- AFTERCARE ---------------*/}
        {itemSelected === "aftercare" && (
          <ShopStore className="shop_modals">
            {aftercareType === "" && (
              <>
                <div
                  onClick={() => {
                    breadCrumbs$.next([
                      {
                        name: "Home",
                        link: "/main",
                        hasCrumb: true,
                      },
                      {
                        name: "Aftercare",
                        link: "/store",
                        hasCrumb: true,
                      },
                      {
                        name: "Healing",
                        link: "",
                        hasCrumb: false,
                      },
                    ]);
                    setAftercareType("healing");
                  }}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      <img src={store?.healingModal?.img} alt="" />
                    </div>
                    <div className="shop_modals_item_info">
                      <div className="shop_modals_item_info_name">
                        <div className="shop_modals_item_info_name_top">
                          {store?.healingModal?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => {
                    breadCrumbs$.next([
                      {
                        name: "Home",
                        link: "/main",
                        hasCrumb: true,
                      },
                      {
                        name: "Aftercare",
                        link: "/store",
                        hasCrumb: true,
                      },
                      {
                        name: "Soap",
                        link: "",
                        hasCrumb: false,
                      },
                    ]);
                    setAftercareType("soap");
                  }}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      <img src={store?.soapModal?.img} alt="" />
                    </div>
                    <div className="shop_modals_item_info">
                      <div className="shop_modals_item_info_name">
                        <div className="shop_modals_item_info_name_top">
                          {store?.soapModal?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => {
                    breadCrumbs$.next([
                      {
                        name: "Home",
                        link: "/main",
                        hasCrumb: true,
                      },
                      {
                        name: "Supplies",
                        link: "/store",
                        hasCrumb: true,
                      },
                      {
                        name: "Marker",
                        link: "",
                        hasCrumb: false,
                      },
                    ]);
                    setAftercareType("marker");
                  }}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      <img src={store?.markerModal?.img} alt="" />
                    </div>
                    <div className="shop_modals_item_info">
                      <div className="shop_modals_item_info_name">
                        <div className="shop_modals_item_info_name_top">
                          {store?.markerModal?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {store &&
              aftercareType === "healing" &&
              store?.aftercare?.healing?.map((shirt, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <AfterCareHovered src={shirt?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <AfterCareNotHovered src={shirt?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_modals_item_info">
                    <div className="shop__modals_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {shirt?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {shirt?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container">
                        <div class="tabs">
                          {/* {sizes.map((size, idx) => (
                            <PillComponent id={idx} name={size} />
                          ))} */}
                        </div>
                      </div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addToCart({ ...shirt });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {shirt?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}

            {store &&
              aftercareType === "soap" &&
              store?.aftercare?.soap?.map((shirt, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <AfterCareHovered src={shirt?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <AfterCareNotHovered src={shirt?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_store_item_info">
                    <div className="shop_store_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {shirt?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {shirt?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container"></div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addToCart({ ...shirt });
                        }}
                        shirt
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {shirt?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}

            {store &&
              aftercareType === "marker" &&
              store?.aftercare?.marker?.map((shirt, idx) => (
                <div
                  onMouseEnter={() => setItemHovered(idx)}
                  onMouseLeave={() => setItemHovered(-1)}
                  className="shop_modals_item"
                >
                  <div className="shop_modals_item_product">
                    <div className="shop_modals_item_product_img">
                      {itemHovered !== idx && (
                        <AfterCareHovered src={shirt?.img?.front} alt="" />
                      )}
                      {itemHovered === idx && (
                        <AfterCareNotHovered src={shirt?.img?.back} alt="" />
                      )}
                    </div>
                  </div>
                  <div className="shop_store_item_info">
                    <div className="shop_store_item_info_name">
                      <div className="shop_store_item_info_name_top">
                        {shirt?.name}
                      </div>
                      <div className="shop_store_item_info_name_bottom">
                        {shirt?.description}
                      </div>
                    </div>
                    <div className="shop_store_item_info_desc">
                      <div class="container"></div>
                    </div>
                    <div className="shop_store_item_info_price">
                      <BuyButton
                        onClick={() => {
                          toast.success("Item added to cart");
                          addToCart({ ...shirt });
                        }}
                        itemHovered={itemHovered === idx}
                      >
                        <BuyIcon>
                          <svg
                            width="24px"
                            height="24px"
                            stroke-width="1.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="#f3f3f3"
                          >
                            <path
                              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M15 8.5C14.315 7.81501 13.1087 7.33855 12 7.30872M9 15C9.64448 15.8593 10.8428 16.3494 12 16.391M12 7.30872C10.6809 7.27322 9.5 7.86998 9.5 9.50001C9.5 12.5 15 11 15 14C15 15.711 13.5362 16.4462 12 16.391M12 7.30872V5.5M12 16.391V18.5"
                              stroke="#f3f3f3"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          {shirt?.price}
                        </BuyIcon>
                        <BuyNow>Add Cart</BuyNow>
                      </BuyButton>
                    </div>
                  </div>
                </div>
              ))}
          </ShopStore>
        )}
      </div>
      {cartShowing && <CartComponent codes={store?.codes} />}
      <FooterComponent />
    </>
  );
};

export default ShopComponent;
