import React from "react";

const ModalCard = ({
  breadCrumbs$,
  setJewelryType,
  store,
  itemName,
  itemType,
  img,
}) => {
  const handleClick = () => {
    breadCrumbs$.next([
      {
        name: "Home",
        link: "/main",
        hasCrumb: true,
      },
      {
        name: "Jewelry",
        link: "/store",
        hasCrumb: true,
      },
      {
        name: itemName.charAt(0).toUpperCase() + itemName.slice(1),
        link: "",
        hasCrumb: false,
      },
    ]);
    setJewelryType(itemType);
  };

  return (
    <div onClick={handleClick} className="shop_modals_item">
      <div className="shop_modals_item_product">
        <div className="shop_modals_item_product_img">
          <img src={img} alt="" />
        </div>
        <div className="shop_modals_item_info">
          <div className="shop_modals_item_info_name">
            <div className="shop_modals_item_info_name_top">{itemName}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCard;
